//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { SlideXLeftTransition, SlideXRightTransition } from 'vue2-transitions'

export default {
  components: {
    SlideXLeftTransition,
    SlideXRightTransition,
  },

  middleware: 'auth',

  data() {
    return {
      isNavigationOpen: false,
    }
  },

  computed: {
    ...mapState(['device']),
  },

  methods: {
    handleNavigationToggle() {
      this.isNavigationOpen = !this.isNavigationOpen
    },
  },
}
