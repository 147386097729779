//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  components: {
    SlideXLeftTransition,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
}
