import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a32af69 = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _caaf6104 = () => interopDefault(import('../pages/playlists/index.vue' /* webpackChunkName: "pages/playlists/index" */))
const _94e6071e = () => interopDefault(import('../pages/recent.vue' /* webpackChunkName: "pages/recent" */))
const _72d98821 = () => interopDefault(import('../pages/tracks/index.vue' /* webpackChunkName: "pages/tracks/index" */))
const _a3710fde = () => interopDefault(import('../pages/artists/_id.vue' /* webpackChunkName: "pages/artists/_id" */))
const _fb3fcd34 = () => interopDefault(import('../pages/playlists/_id.vue' /* webpackChunkName: "pages/playlists/_id" */))
const _8485c26e = () => interopDefault(import('../pages/tracks/_id.vue' /* webpackChunkName: "pages/tracks/_id" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/artists",
    component: _6a32af69,
    name: "artists"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/playlists",
    component: _caaf6104,
    name: "playlists"
  }, {
    path: "/recent",
    component: _94e6071e,
    name: "recent"
  }, {
    path: "/tracks",
    component: _72d98821,
    name: "tracks"
  }, {
    path: "/artists/:id",
    component: _a3710fde,
    name: "artists-id"
  }, {
    path: "/playlists/:id",
    component: _fb3fcd34,
    name: "playlists-id"
  }, {
    path: "/tracks/:id",
    component: _8485c26e,
    name: "tracks-id"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
