// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/CircularSpUIv3T-Book.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/CircularSpUIv3T-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/CircularSpUIv3T-Black.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{\n  font-family:\"Circular\";\n\n  font-style:normal;\n\n  font-weight:400;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\")\n}\n\n@font-face{\n  font-family:\"Circular\";\n\n  font-style:normal;\n\n  font-weight:700;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\")\n}\n\n@font-face{\n  font-family:\"Circular\";\n\n  font-style:normal;\n\n  font-weight:900;\n\n  font-display:swap;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\")\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
