import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Button: () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  SpotifyImg: () => import('../../components/SpotifyImg.vue' /* webpackChunkName: "components/spotify-img" */).then(c => wrapFunctional(c.default || c)),
  Statistic: () => import('../../components/Statistic.vue' /* webpackChunkName: "components/statistic" */).then(c => wrapFunctional(c.default || c)),
  Tag: () => import('../../components/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c)),
  UserHeader: () => import('../../components/UserHeader.vue' /* webpackChunkName: "components/user-header" */).then(c => wrapFunctional(c.default || c)),
  ArtistItem: () => import('../../components/Artist/ArtistItem.vue' /* webpackChunkName: "components/artist-item" */).then(c => wrapFunctional(c.default || c)),
  ArtistPopularity: () => import('../../components/Artist/ArtistPopularity.vue' /* webpackChunkName: "components/artist-popularity" */).then(c => wrapFunctional(c.default || c)),
  ListContainer: () => import('../../components/List/ListContainer.vue' /* webpackChunkName: "components/list-container" */).then(c => wrapFunctional(c.default || c)),
  Navigation: () => import('../../components/Navigation/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c)),
  NavigationToggle: () => import('../../components/Navigation/NavigationToggle.vue' /* webpackChunkName: "components/navigation-toggle" */).then(c => wrapFunctional(c.default || c)),
  PlaylistItem: () => import('../../components/Playlist/PlaylistItem.vue' /* webpackChunkName: "components/playlist-item" */).then(c => wrapFunctional(c.default || c)),
  TimeRangeFilter: () => import('../../components/TimeRangeFilter/TimeRangeFilter.vue' /* webpackChunkName: "components/time-range-filter" */).then(c => wrapFunctional(c.default || c)),
  TrackAudioPlayer: () => import('../../components/Track/TrackAudioPlayer.vue' /* webpackChunkName: "components/track-audio-player" */).then(c => wrapFunctional(c.default || c)),
  TrackItem: () => import('../../components/Track/TrackItem.vue' /* webpackChunkName: "components/track-item" */).then(c => wrapFunctional(c.default || c)),
  VisualizationRadarChart: () => import('../../components/Visualization/VisualizationRadarChart.vue' /* webpackChunkName: "components/visualization-radar-chart" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
