//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    as() {
      if (this.$attrs.to) return 'nuxt-link'

      return 'button'
    },
  },
}
